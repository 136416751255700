:root {
    --talenvo-blue: #043299;
    --talenvo-blue-bg2: #b5c5e6;
    --talenvo-blue-bg: #CFD8EC;
    --talenvo-light-blue: #F7F7F7;
    --talenvo-gray: #666;
    --talenvo-gray-bg: #B3B3B380;
    --talenvo-gray2: #F2F4F7;
    --talenvo-gray3: #F5F5F5;
    --talenvo-danger: #FF5454;
    --talenvo-red: #FF0000;
    --talenvo-dark-dark: #333;
    --talenvo-yellow: #F9B712;
    --talenvo-yellow-bg: #f3c247;
    --talenvo-pink: rgba(238, 183, 246, 1);
    --talenvo-pink-bg: rgba(238, 183, 246, 0.64);
    --talenvo-teal: rgba(133, 237, 237, 1);
    --talenvo-teal-bg: rgba(103, 237, 237, 1);
    --talenvo-green: #10C181;
    --talenvo-green-bg: #2cd094;
  }