/* #animation-container *,
#animation-container *:after,
#animation-container *:before {
  box-sizing: border-box;
  transform-style: preserve-3d;
}

:root {
  --size: 90;
  --coefficient: 1px;
  --timeline: 2.6s;
  --delay: 0.65s;
  --rotation-y: -24;
  --rotation-x: 28;
  --color-one: #3a0ca3;
  --color-two: #4361ee;
  --color-three: #4cc9f0;
}

.scene {
  transform: translate3d(0, 0, 100vmin)
    rotateX(calc(var(--rotation-y, 0) * 1deg))
    rotateY(calc(var(--rotation-x, 0) * 1deg));
}


@keyframes scale {
  0%,
  10% {
    transform: scaleX(1) scaleY(1);
  }
  35%,
  100% {
    transform: scaleX(0.5) scaleY(0.5);
  }
}

.shadow {
  width: calc(var(--size) * var(--coefficient));
  position: absolute;
  bottom: 0;
  aspect-ratio: 1;
  transform-origin: 50% 50%;
  background: hsl(210 80% 50% / 0.2);
  transform: rotateX(90deg)
    translate3d(0, 0, calc((var(--size) * (var(--coefficient) * -0.5)) - 1px))
    scale(0.96);
  animation: squish-squosh var(--timeline) var(--delay) infinite,
    fade var(--timeline) var(--delay) infinite;
}

.loader {
  --depth: var(--size);
  --color: var(--color-one, #8338ec);
  width: calc(var(--depth) * var(--coefficient));
  aspect-ratio: 1;
  transform-origin: 50% 50%;
  animation: squish-squosh var(--timeline) var(--delay) infinite;
}

.spinner {
  animation: spin var(--timeline) var(--delay) infinite;
}

.jumper {
  animation: jump var(--timeline) var(--delay) infinite;
}

@keyframes squish-squosh {
  0%,
  50%,
  60% {
    scale: 1 1 1;
  }
  10%,
  35% {
    scale: 1.2 0.8 1.2;
  }
  25% {
    scale: 0.8 1.2 0.8;
  }
  70% {
    scale: 1 1 2;
  }
  80% {
    scale: 2 1 2;
  }
  90%,
  100% {
    scale: 2 2 2;
  }
}

@keyframes fade {
  0%,
  10%,
  40%,
  50%,
  60%,
  100% {
    opacity: 1;
  }
  25% {
    opacity: 0.5;
  }
}

@keyframes spin {
  0%,
  10% {
    rotate: 0deg;
  }
  30%,
  100% {
    rotate: -360deg;
  }
}
@keyframes jump {
  0%,
  10%,
  35%,
  50% {
    translate: 0 0;
  }
  25% {
    translate: 0 -150%;
  }
} */

/* Cuboid boilerplate code */
/* .cuboid {
  width: 100%;
  height: 100%;
  position: relative;
}
.cuboid__side {
  background: var(--color);
  position: absolute;
}
.cuboid__side:nth-of-type(1) {
  --b: 1.1;
  height: calc(var(--depth, 20) * var(--coefficient));
  width: 100%;
  top: 0;
  transform: translate(0, -50%) rotateX(90deg);
}
.cuboid__side:nth-of-type(2) {
  --b: 0.9;
  --color: var(--color-three, #ff006e);
  height: 100%;
  width: calc(var(--depth, 20) * var(--coefficient));
  top: 50%;
  right: 0;
  transform: translate(50%, -50%) rotateY(90deg);
}
.cuboid__side:nth-of-type(3) {
  --b: 1;
  width: 100%;
  height: calc(var(--depth, 20) * var(--coefficient));
  bottom: 0;
  transform: translate(0%, 50%) rotateX(90deg);
}
.cuboid__side:nth-of-type(4) {
  --b: 1;
  --color: var(--color-three, #ff006e);
  height: 100%;
  width: calc(var(--depth, 20) * var(--coefficient));
  left: 0;
  top: 50%;
  transform: translate(-50%, -50%) rotateY(90deg);
} */
/* .cuboid__side:nth-of-type(5) {
  --b: 1;
  --color: var(--color-two, #3a86ef);
  height: 100%;
  width: 100%;
  transform: translate3d(
    0,
    0,
    calc(var(--depth, 20) * (var(--coefficient) * 0.5))
  );
  top: 0;
  left: 0;
} */
/* .cuboid__side:nth-of-type(6) {
  --b: 1.2;
  height: 100%;
  width: 100%;
  transform: translate3d(
      0,
      0,
      calc(var(--depth, 20) * (var(--coefficient) * -0.5))
    )
    rotateY(180deg);
  top: 0;
  left: 0;
} */

.lds-spinner,
.lds-spinner div,
.lds-spinner div:after {
  box-sizing: border-box;
}
.lds-spinner {
  color: #003399;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: ' ';
  display: block;
  position: absolute;
  top: 3.2px;
  left: 36.8px;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #003399;
}

.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
