@import './Variables.css';

.gradient-container {
  background: linear-gradient(
    125deg,
    rgba(146, 230, 219, 0.1) 27%,
    rgba(29, 98, 115, 0.39) 121.04%
  );
  box-shadow: 0px 2px 5px 0px rgba(4, 50, 153, 0.1);
}

/* BASE BUTTON STYLES START */

.baseButton,
.baseButton2 {
  cursor: pointer;
  height: 45px;
  border-radius: 8px;
  line-height: 150%;
  padding: 10px 20px;
  transition: all 0.5 ease-in-out;
}

.buttonOutlineBlue {
  height: 33px;
  padding: 8px 20px 8px 20px;
  transition: all 0.5 ease-in-out;
  border-radius: 10px;
  color: var(--talenvo-blue);
  border: 1px solid var(--talenvo-blue);
}

.buttonOutlineDark {
  transition: all 0.5 ease-in-out;
  padding: 8px 20px 8px 20px;
  height: 33px;
  color: black;
  border: 1px solid var(--talenvo-gray-bg);
}

.buttonOutlineBlueInverted {
  height: 44px;
  padding: 8px 20px 8px 20px;
  transition: all 0.5 ease-in-out;
  border-radius: 30px;
  color: #fff;
  border: 1px solid #fff;
}

.baseThemedButton {
  color: #000;
}

.baseThemedButton.base-teal {
  background-color: var(--talenvo-teal);
}

.baseThemedButton.base-green {
  background-color: var(--talenvo-green);
}

.baseThemedButton.base-blue {
  background-color: var(--talenvo-blue);
}

.baseThemedButton.base-gold {
  background-color: var(--talenvo-yellow);
}

.baseThemedButton.base-pink {
  background-color: var(--talenvo-pink);
}

.baseThemedButton.base-gold:not(:disabled):hover,
.baseThemedButton.base-teal:not(:disabled):hover,
.baseThemedButton.base-pink:not(:disabled):hover,
.baseThemedButton.base-blue:not(:disabled):hover,
.baseThemedButton.base-green:not(:disabled):hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.2);
}

.baseButton1 {
  background: var(--talenvo-blue);
  color: #fff;
}

.buttonOutlineBlue:disabled,
.buttonOutlineDark:disabled {
  border: 1px solid var(--talenvo-gray-bg);
  color: var(--talenvo-gray-bg);
  background-color: #fff;
}

.buttonOutlineDark:not(:disabled):hover {
  filter: drop-shadow(0px 1px 2px gray);
}

.buttonOutlineBlueInverted:disabled {
  border: 1px solid var(--talenvo-gray-bg);
  color: var(--talenvo-gray-bg);
}

.buttonOutlineBlue:not(:disabled):hover {
  background-color: var(--talenvo-blue);
  color: #fff;
}

.baseButton1:not(:disabled):hover {
  background-color: #3053a4;
}

.baseButton1Disabled {
  background-color: var(--talenvo-gray-bg);
  color: var(--talenvo-gray);
}

.buttonOutlineBlueInverted:not(:disabled):hover {
  background-color: #fff;
}

.buttonOutlineBlueInverted:not(:disabled):hover > p {
  color: var(--talenvo-blue);
}

.baseButton.baseButtonWide {
  width: 360px;
}

.baseButton.baseButtonMedium {
  width: 169px;
}

.baseButton.baseButtonSmall {
  width: 132px;
}

.buttonOutlineBlue.taller,
.buttonOutlineBlueInverted.taller,
.buttonOutlineDark.taller {
  height: 45px;
}
/* BASE BUTTON STYLES END */

/* BASE TEXT STYLES START */

.baseText {
  font-style: normal;
  line-height: normal;
}

.baseTextGray {
  color: var(--talenvo-gray);
}

.baseTextBlack {
  color: var(--talenvo-text-dark);
}

.baseTextRed {
  color: var(--talenvo-red);
}

.baseTextWhite {
  color: #fff;
}

.baseTextBlue {
  color: var(--talenvo-blue);
}

.baseTextYellow {
  color: yellow;
}

.baseTextDisabled {
  color: var(--talenvo-gray-bg);
}

.baseTextGreen {
  color: var(--talenvo-green);
}

/* BASE TEXT STYLES END */

/* BASE DP COMPONENT STYLES START */

img.dpSmall {
  width: 43px;
  height: 43px;
}

img.dpLarge {
  width: 128px;
  height: 128px;
}

img.dpMini {
  width: 40px;
  height: 40px;
}

/* BASE DP COMPONENT STYLES END */

/* BASE SIDEBAR ITEM STYLES START */

.sidebarItem {
  border-radius: 10px;
  width: 202px;
  height: 64px;
  transition: all 0.5s ease-in-out;
  margin-bottom: 5px;
}

.sidebarItem.active {
  background: #eaecf0;
  color: #043299;
}

.sidebarItem:hover {
  background: #eaecf0;
}

/* BASE SIDEBAR ITEM STYLES END */

/* USER TAG STYLES START */

div.userTag {
  width: 128px;
  height: 30px;
  border-radius: 50px 50px 0px 0px;
  opacity: 0.5;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* USER TAG STYLES END */

#entireAssessment {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}

.common-styles {
  height: 16;
  width: 16;
  border-radius: 10px;
}

.baseTextRed {
  background-color: (--talenvo-red);
}

.baseTextYellow {
  background-color: yellow;
}

.themeblue {
  color: blue;
  /* ... other styles */
}
